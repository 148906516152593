// LoadingScreen.js
import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import logo from "../../assets/icons/Tlogo.png"; 
import { LanguageContext } from '../../context/LanguageContext';
import fr from "../../locales/fr/translation.json"
import en from "../../locales/en/translation.json"

const flash = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
`;

const FlashingLogo = styled.img`
  width: 150px; /* Adjust size as needed */
  animation: ${flash} 1s infinite;
`;

const LoadingScreen = () => {

  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? en : fr;
  
  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-black text-white z-[99999] gap-6">
      <FlashingLogo src={logo} alt="Logo" />
      <p className="text-xl uppercase">{translations["loading"]}...</p>
    </div>
  );
};

export default LoadingScreen;
