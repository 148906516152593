import React, { useContext } from 'react'
import { LanguageContext } from '../../context/LanguageContext';
import en from "../../locales/en/translation.json"
import fr from "../../locales/fr/translation.json"

const AboutME = () => {

  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? en : fr;

  return (
    <div id="aboutMe" className='text-white pb-12 relative z-50 '>
      <div className="container m-auto">
            <div className='space-y-3 xl:pr-36 p-4 '>
                <p data-aos="fade-up" className='text-sky-800 uppercase'>{translations['overview']}</p>
                <h1 data-aos="fade-up" data-aos-delay="300" className='uppercase lg:text-5xl text-3xl'>{translations['about me']}</h1>
                <p className=' lg:text-2xl sm:text-xl text-md' data-aos="fade-up" data-aos-delay="300">
                {translations['about me desc']}
                </p>
            </div>
      </div>
    </div>
  )
}

export default AboutME
