import React, { useContext } from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import insat from "../../assets/icons/insat.jpg"
import Ernst from "../../assets/images/ERNST.png"
import Freelance from "../../assets/images/Freelance.png"
import IEEE from "../../assets/images/IEEE.png"
import Aerobotix from "../../assets/images/Aerobotix.png"
import { LanguageContext } from '../../context/LanguageContext';
import fr from "../../locales/fr/translation.json"
import en from "../../locales/en/translation.json"

const Experience = () => {

    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? en : fr;

    const myExperience = [
        {
            "title": translations["web dev intern"],
            "institute": "ERNST",
            "description": translations['web dev intern desc'],
            "date": `${translations["july"]} 2024 - Sept 2024`,
            "img": Ernst,
        },
        {
            "title": "Freelance",
            "institute": "ASPIRE",
            "description": translations["aspire desc"],
            "date": `${translations['june']} 2023 - ${translations["july"]} 2023`,
            "img": Freelance,
        },
        {
            "title": translations["pfa"],
            "institute": "INSAT",
            "description": translations["pfa desc"],
            "date": `${translations['feb']} 2023 - ${translations['may']} 2023`,
            "img": insat,
        },
        {
            "title": translations['web development'],
            "institute": "Freelance",
            "description": translations['web development desc'],
            "date": `${translations['july']} 2023 - ${translations['august']} 2023`,
            "img": Freelance,
        },
        {
            "title": translations["web master"],
            "institute": "IEEE INSAT",
            "description": translations["web master desc"],
            "date": `Jan 2023 - ${translations['april']} 2023`,
            "img": IEEE,
        },
        {
            "title": translations['aeroday site'],
            "institute": "Aeroday INSAT",
            "description": translations['aeroday site desc'],
            "date": "Oct 2022 - Dec 2022",
            "img": Aerobotix,
        },
        {
            "title": translations["mobile app intern"],
            "institute": "IEEE INSAT Student Branch and Internee IEEE INSAT",
            "description": translations['mobile app intern desc'],
            "date": `jan 2022  - ${translations['june']} 2022`,
            "img": IEEE,
        },
    ] 

  return (
    <div className='text-white pb-12 relative z-50'>
      <div className="container m-auto">
            <div className='space-y-3 xl:pr-36 p-4 '>
                <p data-aos="fade-up" className='text-sky-800 uppercase'>{translations['about my']}</p>
                <h1 data-aos="fade-up" data-aos-delay="300" className='uppercase lg:text-5xl text-3xl'>{translations['experience']}</h1>
                <VerticalTimeline>
                    {
                        myExperience.map((elt) => (
                            <VerticalTimelineElement
                            key={elt.title}
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'rgba(7, 89, 133, 0.4)', color: '#fff'}}
                            contentArrowStyle={{ borderRight: '7px solid  rgba(7, 89, 133, 0.4)' }}
                            date={elt.date}
                            iconStyle={{ background: 'white', color: '#fff' }}
                            icon={<img alt='experience' style={{border:"solid 2px white", borderRadius:"100%"}} src={elt.img} />}
                        >
                            <h3 className="vertical-timeline-element-title">{elt.title}</h3>
                            <h4 className="text-[#820608] vertical-timeline-element-subtitle c-">{elt.institute}</h4>
                            <p>
                                {elt.description}
                            </p>
                        </VerticalTimelineElement>
                        ))
                    }
                </VerticalTimeline>
            </div>
      </div>
    </div>
  )
}

export default Experience
