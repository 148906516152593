import React, { useContext } from 'react'
import reactJs from "../../assets/icons/react.png"
import nodeJs from "../../assets/icons/node-js-icon-454x512-nztofx17.png"
import expressJs from "../../assets/icons/1646733543.webp"
import html from "../../assets/icons/HTML5_Badge.svg.png"
import css from "../../assets/icons/CSS3_logo.svg"
import Js from "../../assets/icons/JavaScript-logo.png"
import python from "../../assets/icons/python.png"
import symfony from "../../assets/icons/symfony.png"
import mySQL from "../../assets/icons/mySQL.png"
import tailwind from "../../assets/icons/tailwind.png"
import flutter from "../../assets/icons/flutter.png"
import angular from "../../assets/icons/Angular_full_color_logo.svg.png"
import java from "../../assets/icons/java.png"
import dotNet from "../../assets/icons/Microsoft_.NET_logo.svg.png"
import threeJS from "../../assets/icons/threeJS.png"
import photoshop from "../../assets/icons/photoshop.png"
import illustrator from "../../assets/icons/illustrator.png"
import mongoDB from "../../assets/icons/mongoDB.png"
import { LanguageContext } from '../../context/LanguageContext';
import fr from "../../locales/fr/translation.json"
import en from "../../locales/en/translation.json"

const FamilairWith = () => {

    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? en : fr;

    const skills = [
        {
            "name": "ReactJS",
            "icon": reactJs
        },
        {
            "name": "NodeJS",
            "icon": nodeJs
        },
        {
            "name": "ExpressJS",
            "icon": expressJs
        },
        {
            "name": "HTML",
            "icon": html
        },
        {
            "name": "CSS",
            "icon": css
        },
        {
            "name": "JS",
            "icon": Js
        },
        {
            "name": "mongoDB",
            "icon": mongoDB
        },
        {
            "name": "Tailwind",
            "icon": tailwind
        },
        {
            "name": "threeJS",
            "icon": threeJS
        },
        {
            "name": "angular",
            "icon": angular
        },
        {
            "name": ".NET",
            "icon": dotNet
        },
        {
            "name": "python",
            "icon": python
        },
        {
            "name": "flutter",
            "icon": flutter
        },        {
            "name": "java",
            "icon": java
        },
        {
            "name": "symfony",
            "icon": symfony
        },
        {
            "name": "mySQL",
            "icon": mySQL
        },
        {
            "name": "photoshop",
            "icon": photoshop
        },
        {
            "name": "illustrator",
            "icon": illustrator
        },

    ]
  return (
    <div className='w-[80%] m-auto'>
        <h1 data-aos="fade-up" className='text-3xl text-sky-800 uppercase text-center my-[50px]'>{translations['familiar with']}</h1>
        <div className='flex flex-wrap items-center justify-center gap-3 m-auto'>
        {
            skills.map((skill, i) => (
                <div key={i} data-aos="fade-up" className='h-[85px] w-[85px] bg-white border-white border-7 rounded-[100%]  overflow-hidden flex items-center justify-center'>
                   <img key={i} className='max-w-[70%] max-h-[70%]' src={skill.icon} alt={skill.name} />
                </div>
            ))
        }
        </div>
    </div>
  )
}

export default FamilairWith
