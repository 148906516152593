import React, { useContext } from 'react'
import ProjectCard from '../ProjectCard/ProjectCard'
import angelVideo from "../../assets/videos/angelVideo.MOV"
import aspireVideo from "../../assets/videos/aspireVideo.MOV"
import orderTrackingSystem from "../../assets/videos/orderTrackingSystem.MOV"
import portfolioImage from "../../assets/images/Screenshot 2024-10-10 at 9.47.05 PM.png"
import teledetection from "../../assets/images/teledetection.png"
import pfa from "../../assets/images/pfa.png"
import { LanguageContext } from '../../context/LanguageContext';
import fr from "../../locales/fr/translation.json"
import en from "../../locales/en/translation.json"

const Projects = () => {

  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? en : fr;

  const projects = [
    {
      video: orderTrackingSystem,
      title: translations['order tracking system'],
      description: translations['order tracking system desc'],
      link: "https://www.linkedin.com/posts/talel-ayed-39ab85201_reactjs-ecommerce-ordertracking-activity-7263984788871462912-2dzK?utm_source=share&utm_medium=member_desktop",
      tags: ["ReactJS", "Javascript", "JWT", "RESTFUL APIs", "Data Visualization"]
    },
    {
      video: angelVideo,
      title: translations['angelstore'],
      description: translations['angelstore desc'],
      link: "https://angelstore.onrender.com/",
      tags: ["ReactJS", "NodeJS", "MongoDB", "TailwindCSS", "Express", "JWT", "Redux", "KonvaJS", "Render"]
    },
    {
      video: aspireVideo,
      title: translations['aspire'],
      description: translations['aspire desc'],
      link: "https://aspire-hura.onrender.com/",
      tags: ["ReactJS", "NodeJS", "MongoDB", "TailwindCSS", "Express", "JWT", "Redux", "ThreeJS", "Blender", "Render"]
    },
    {
      image: portfolioImage,
      title: "Portfolio",
      description: translations['portfolio desc'],
      link: "https://talel-ayed.me",
      tags: ["ReactJS", "TailwindCSS", "ThreeJS", "Github Pages"]
    },
    {
      image: pfa,
      title: translations['pfa'],
      description: translations['pfa desc'],
      link: "https://drive.google.com/drive/folders/1zwvlcjvwzBtQ1lzCNEKzKIEn72X8Q5Bm?usp=sharing",
      tags: ["Python", "TensorFlow", "Keras", "Flickr8k Dataset", "Xception Model", "CNN", "RNN", "LSTM", "NLP"]
    },
    {
      image: teledetection,
      title: "Teledetection",
      description: translations['teledetection desc'],
      link: "https://drive.google.com/drive/folders/1C_yLa3d0Fqw49IXB3ZbXknaFS4hTr-9P?usp=sharing",
      tags: ["Python", "Scikit-learn", "Pavia Center Dataset"]
    },
  ]
  return (
    <div id="projects" className='mb-10 w-[80%] m-auto'>
          <p data-aos="fade-up" className='text-sky-800 uppercase'>{translations['some of my']}</p>
          <h1 data-aos="fade-up" data-aos-delay="300" className='uppercase lg:text-5xl text-3xl text-white'>{translations['projects']}</h1>
          <div className='mt-5 grid gap-10'>
            {
              projects.map((project, index) => (
              
                <ProjectCard key={index} project={{...project}} />
              ))
            }
          </div>
    </div>
  )
}

export default Projects
