import React, { useState } from 'react'
import styled, { keyframes } from "styled-components";

const flash = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
`;

const LoadingText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
  border-radius: 5px;
  animation: ${flash} 1s infinite;
`;

const ProjectCard = ({project}) => {

  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoadedData = () => {
    setIsLoaded(true);
  };
    
  return (
    <div data-aos="fade-up" className='lg:w-[45vw] w-[85vw] p-[20px] flex flex-col gap-4 bg-sky-800/40 text-white rounded-xl m-auto'>
        <div className='m-auto'>
          {
           project.video && 
           <div className='relative  w-full h-auto'>
              {!isLoaded && <LoadingText>Loading Projects...</LoadingText>}
              <video onLoadedData={handleLoadedData}
                    className={`${isLoaded ? "visible" : "invisible"}`} 
                    loop 
                    autoPlay 
                    muted 
                    src={project.video} 
              />
           </div>
          }
          {
           project.image && 
            <img alt='project' className='' src={`${project.image}`} />
          }
        </div>
        <h1 className='text-white font-bold text-lg cursor-pointer'>{project.title}</h1>
        <div className='my-auto'>
          <p className='text-gray-400'>{project.description}</p>
        </div>
        <div className='flex flex-wrap gap-2'>
            {
                project.tags?.map((tag, index) => (
                        <p key={index} className='p-1 my-auto bg-black rounded-xl text-md'>
                            #{tag}
                        </p>
                ))
            }
        </div>
        <a href={project.link} className='underline cursor-pointer block'>
          <p className='w-full truncate'>{project.link}</p>
        </a>
    </div>
  )
}

export default ProjectCard
