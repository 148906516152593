import React, { useContext } from 'react'
import linkedIn from "../../assets/icons/linkedIn.svg"
import mail from "../../assets/icons/mail.svg"
import { LanguageContext } from '../../context/LanguageContext';
import fr from "../../locales/fr/translation.json"
import en from "../../locales/en/translation.json"

const Footer = () => {

    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? en : fr;

    const socialMedia = [
        {
            "title": "linkedIn",
            "url": "https://www.linkedin.com/in/talel-ayed-39ab85201",
            "icon": linkedIn,
            "content": "linkedin"
        },
        {
            "title": "mail",
            "url": "",
            "icon": mail,
            "content": "talel.ayed@insat.ucar.tn"
        },
    ];
  return (
    <div className='px-0 py-10'>
        <div className='container flex sm:justify-between justify-center items-center gap-10 max-sm:flex-col m-auto w-[80%]'>
            <p className='caption text-n-4 lg:block text-white'>© {new Date().getFullYear()}. {translations['copyright']} </p>
            <ul className='flex gap-5 felx-wrap'>
            {
                socialMedia.map((elt) => (
                    <li key={elt.title} className='flex justify-center items-center'>
                        <a
                        href={elt.url}
                        className='pointer flex items-center justify-center w-10 h-10 bg-n7 rounded-full transition-colors hover:bg-n-6'
                        >
                            <img src={elt.icon} width={16} height={16} alt={elt.title} />
                        </a>
                        <a
                        href={elt.url}
                        className='pointer transition-colors hover:bg-n-6'
                        >
                            <p className='text-white underline'>{elt.content}</p>
                        </a>
                    </li>
                ))
            }
        </ul>
        </div>
    </div>
  )
}

export default Footer
