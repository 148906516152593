import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {LanguageContext} from "../../context/LanguageContext"
import whiteLogo from "../../assets/icons/Tlogo.png"
import blackLogo from "../../assets/icons/TlogoBlack.png"

const Container = styled.div`
  width: 100vw;
  z-index: 999;
  position: fixed;
  background-color: none;
  display: flex;
  justify-content: center;
`;
const Wrapper = styled.div`
  width: 100%;
  padding: 10px 10px;
  text-align: center;
  display: flex;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  margin:auto 0 auto 0;
`;

const Right = styled.div`

  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
  margin-bottom: auto;
`;

const Language = styled.div`
  font-size: 14px;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
`;

const MenuRightItem = styled.h1`
  cursor: pointer;
  margin: auto 0 auto 28px;
  font-size: min(20px, 2vw);
  font-weight: bold;
  text-transform: uppercase;
`;

const MenuLeftItem = styled.h1`
  cursor: pointer;
  margin: auto 0 auto 0;
  font-size: min(20px, 2vw);
  font-weight: bold;
  text-transform: uppercase;
`;


const LinkActiveStyle = {cursor:"pointer", textDecoration: "none", color: "black", margin:"auto 0 auto 0" };
const LinkStyle = {cursor:"pointer", textDecoration: "none", color: "white", margin:"auto 0 auto 0" };
const ActiveStyle = {
    background: 'white',
    boxShadow: 'rgb(0 0 0 / 2%) 0px 1px 1px 0px, rgb(0 0 0 / 3%) 0px 2px 2px 0px, rgb(0 0 0 / 2%) 0px 4px 4px 0px, rgb(0 0 0 / 2%) 0px 8px 8px 0px, rgb(0 0 0 / 2%) 0px 16px 16px 0px',
    WebkitTransition: 'background 0.7s ease-in-out',
    MozTransition: 'background 0.7s ease-in-out',
    OTransition: 'background 0.7s ease-in-out',
    transition: 'background 0.7s ease-in-out, all 0.7s',
    marginTop: '0px'
}

const Navbar = () => {
  const { language, setLanguage } = useContext(LanguageContext);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    setShowDropdown(false);
  };
  const [navbar, setNavbar] = useState(false);

  const changeBackgroundColor = () => {
    if (window.scrollY >= 15) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackgroundColor();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackgroundColor);
  });


  return (
    <Container data-aos="fade-down" style={navbar ? ActiveStyle : {}}>
      <Wrapper>
        <Left>
          {
            navbar && <img alt="blackLogo" className="max-h-[2vh] max-w-full m-auto mr-2" src={blackLogo} />
          }
          {
            !navbar && <img alt="whiteLogo" className="max-h-[2vh] max-w-full m-auto mr-2" src={whiteLogo} />
          }
            <a style={navbar ? LinkActiveStyle : LinkStyle} href="#hero">
                    <MenuLeftItem>{language === 'en' ? 'Home' : 'Accueil'}</MenuLeftItem>
            </a>
        </Left>
        <Right>
            <a
                style={navbar ? LinkActiveStyle : LinkStyle}
                href="#aboutMe"
            >
                <MenuRightItem >{language === 'en' ? 'About Me' : 'À propos de moi'}</MenuRightItem>
            </a>
            <a
                style={navbar ? LinkActiveStyle : LinkStyle}
                href="#education"
            >
                <MenuRightItem>{language === 'en' ? 'Education' : 'Éducation'}</MenuRightItem>
            </a>
            <a
                style={navbar ? LinkActiveStyle : LinkStyle}
                href="#projects"
            >
                <MenuRightItem>{language === 'en' ? 'Projects' : 'Projets'}</MenuRightItem>
            </a>
            <div className="relative ml-3 mr-0 md:text-sm text-[0.6rem]" style={navbar ? {color: "black"} : {color: "white"}}>
        <button
          className="flex items-center space-x-1 p-1 border rounded-md"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <img
            src={language === 'en' ? 'https://flagcdn.com/w20/us.png' : 'https://flagcdn.com/w20/fr.png'}
            alt={language === 'en' ? 'English' : 'French'}
            className="rounded-full lg:w-4 lg:h-4 w-2 h-2"
          />
          <span>{language === 'en' ? 'English' : 'Français'}</span>
        </button>
        {showDropdown && (
          <div className="absolute right-0 mt-2 w-40 bg-white rounded-md shadow-lg z-10 text-black">
            <button
              className={`flex items-center space-x-2 p-2 w-full hover:bg-gray-100 ${
                language === 'en' ? 'border-2 border-blue-500' : ''
              }`}
              onClick={() => handleLanguageChange('en')}
            >
              <img
                src="https://flagcdn.com/w20/us.png"
                alt="English"
                className="rounded-full w-6 h-6"
              />
              <span>English</span>
            </button>
            <button
              className={`flex items-center space-x-2 p-2 w-full hover:bg-gray-100 ${
                language === 'fr' ? 'border-2 border-blue-500' : ''
              }`}
              onClick={() => handleLanguageChange('fr')}
            >
              <img
                src="https://flagcdn.com/w20/fr.png"
                alt="French"
                className="rounded-full w-6 h-6"
              />
              <span>Français</span>
            </button>
          </div>
        )}
        </div>

        </Right >
      </Wrapper>
    </Container>
  );
};
export default Navbar;