import React, { useContext } from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import insat from "../../assets/icons/insat.jpg"
import school from "../../assets/icons/school.png"
import { LanguageContext } from '../../context/LanguageContext';
import fr from "../../locales/fr/translation.json"
import en from "../../locales/en/translation.json"

const Education = () => {

    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? en : fr;

    const myEducation = [
        {
            "title": translations["soft eng"],
            "institute": translations["insat"],
            "description": translations["soft eng desc"],
            "date": "2022 - 2024",
            "img": insat,
        },
        {
            "title": translations["pre-eng"],
            "institute": translations["insat"],
            "description": translations["pre-eng desc"],
            "date": "2020 - 2022",
            "img": insat,
        },
        {
            "title": translations["bac"],
            "institute": translations['bac school'],
            "description": translations["bac desc"],
            "date": "2016 - 2020",
            "img": school,
        },
    ] 

  return (
    <div id="education" className='text-white pb-12 relative z-50 mt-20'>
      <div className="container m-auto">
            <div className='space-y-3 xl:pr-36 p-4 '>
                <p data-aos="fade-up" className='text-sky-800 uppercase'>{translations['personal journey']}</p>
                <h1 data-aos="fade-up" data-aos-delay="300" className='uppercase lg:text-5xl text-3xl'>{translations['education']}</h1>
                <VerticalTimeline>
                    {
                        myEducation.map((elt) => (
                            <VerticalTimelineElement
                            key={elt.title}
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'rgba(7, 89, 133, 0.4)', color: '#fff'}}
                            contentArrowStyle={{ borderRight: '7px solid  rgba(7, 89, 133, 0.4)' }}
                            date={elt.date}
                            iconStyle={{ background: 'white', color: '#fff' }}
                            icon={<img alt='education' style={{border:"solid 2px white", borderRadius:"100%"}} src={elt.img} />}
                        >
                            <h3 className="vertical-timeline-element-title">{elt.title}</h3>
                            <h4 className="text-[#820608] vertical-timeline-element-subtitle c-">{elt.institute}</h4>
                            <p>
                                {elt.description}
                            </p>
                        </VerticalTimelineElement>
                        ))
                    }
                </VerticalTimeline>
            </div>
      </div>
    </div>
  )
}

export default Education
