import React, { useContext } from 'react'
import fullStackDeveloper from "../../assets/icons/full-stack-dev.svg"
import uiUxDeveloper from "../../assets/icons/ui-ux-white.svg"
import graphicDesigner from "../../assets/icons/graphic-designer.svg"
import biEnthusiast from "../../assets/icons/bi.svg"
import computerVisionEnthusiast from "../../assets/icons/computer-vision.svg"
import { LanguageContext } from '../../context/LanguageContext';
import fr from "../../locales/fr/translation.json"
import en from "../../locales/en/translation.json"

const SkillCard = () => {

  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? en : fr;

  const SkillData =[
    {
        title: translations['full stack'],
        description: "vijdhdfjdjnfjdbvjkdbvbdjvb",
        icon: <img alt='Full Stack Developer' src={fullStackDeveloper} className='text-7xl max-w-[70%] ' icon="fa-light fa-rectangle-code" />,
        aosDelay: "300",
    },
    {
        title: translations['ui/ux'],
        description: "vijdhdfjdjnfjdbvjkdbvbdjvb",
        icon: <img alt='UI/UX Designer' src={uiUxDeveloper} className='text-7xl max-w-[70%] ' icon="fa-light fa-rectangle-code" />,
        aosDelay: "300",
    },
    {
        title: translations['comp vis ent'],
        description: "vijdhdfjdjnfjdbvjkdbvbdjvb",
        icon: <img alt='Computer Vision Enthusiast' src={computerVisionEnthusiast} className='text-7xl max-w-[70%] ' icon="fa-light fa-rectangle-code" />,
        aosDelay: "300",
    },
    {
        title: translations['bi ent'],
        description: "vijdhdfjdjnfjdbvjkdbvbdjvb",
        icon: <img alt='BI Enthusiast' src={biEnthusiast} className='text-7xl max-w-[70%] ' icon="fa-light fa-rectangle-code" />,
        aosDelay: "300",
    },
    {
        title: translations['graphic designer'],
        description: "vijdhdfjdjnfjdbvjkdbvbdjvb",
        icon: <img alt='Graphic Designer' src={graphicDesigner} className='text-7xl max-w-[70%] ' icon="fa-light fa-rectangle-code" />,
        aosDelay: "300",
    }
]

  return (
    <div id="skillCards" className='text-white relative z-50 w-[80%] m-auto'>
      <div className="container">
        <div className='min-h-[400px]'>
            <div className="grid grid-cols-1 2xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-6 relative z-10">
                {
                    SkillData.map((skill) =>(
                        <div key={skill.title} data-aos="fade-up" data-aos-delay={skill.aosDelay}
                         className='min-h[180px] flex flex-col gap-6 justify-center items-center bg-sky-800/40 rounded-xl backdrop-blur-sm text-center text-2xl py-8 px-3 w-full lg:w-[300px] mx-auto'>
                            {skill.icon}
                            <h1>{skill.title}</h1>
                        </div>
                    ))
                }
            </div>
            {/* <img src={gif} alt="" className='h-[200px] w-full object-cover mix-blend-screen -translate-y-24 relative z-[0] ' /> */}
        </div>
      </div>
    </div>
  )
}

export default SkillCard
