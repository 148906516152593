import React, { useEffect, useState, useContext } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles";
import "./App.css";
import particlesOptions from "./particles.json";
import Navbar from "./components/Navbar/Navbar";
import Hero from "./components/Hero/Hero";
import AOS from "aos";
import "aos/dist/aos.css";
import AboutME from "./components/AboutMe/AboutME";
import SkillCard from "./components/SkillCard/SkillCard";
import Education from "./components/Education/Education";
import Footer from "./components/Footer/Footer";
import Experience from "./components/Experience/Experience";
import FamilairWith from "./components/FamilairWith/FamilairWith";
import Projects from "./components/Projects/Projects";
import { LanguageContext } from './context/LanguageContext';
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import { LoadingContext } from "./context/LoadingContext";

function App() {
    const { language, setLanguage } = useContext(LanguageContext);
    const { isLoading } = useContext(LoadingContext);
    
    useEffect(() => {
        AOS.init({
            duration: 1200,
            easing: "ease-in-out"
        });
    }, []);

    const [init, setInit] = useState(false);

    useEffect(() => {
        if (init) {
            return;
        }

        initParticlesEngine(async (engine) => {
            await loadFull(engine);
        }).then(() => {
            setInit(true);
        });
    }, [init]);

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
    };


    useEffect(() => {
        console.log(isLoading);
        
  
      }, [isLoading])

    

    return (<>
        {
            isLoading && <LoadingScreen/>
        }
                    <div>
                    <Navbar onLanguageChange={handleLanguageChange} />
                    {init && <Particles options={particlesOptions} />}
                    <Hero/>
                    <div className="w-[100%]">
                        <AboutME />
                        <SkillCard />
                        <Education />
                        <Projects />
                        <Experience />
                        <FamilairWith />
                        <Footer />
                    </div>
                </div>
                </>
    );
}

export default App;
